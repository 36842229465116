import fetchApi from "ab-fetch-api";

import { PAGE_WITH_TRANSLATION } from "ab-api-fragments";

export async function getModCross() {
  const data = await fetchApi(
    `
    {
      modulosCross {
        modCrossDisfrute {
          animated
          cards {
            label
            labelEn
            labelDe
            image {
              mediaItemUrl
            }
            imagemobile {
              mediaItemUrl
            }
            page {
              ${PAGE_WITH_TRANSLATION}
            }
          }
          classname
          fieldGroupName
          text
          title
          titleEn
          titleDe
        }
        modCrossExperiencias {
          animatedexperiencias
          cardsexperiencias {
            label
            labelEn
            labelDe
            image {
              mediaItemUrl
            }
            imagemobile {
              mediaItemUrl
            }
            page {
              ${PAGE_WITH_TRANSLATION}
            }
          }
          classnameexperiencias
          fieldGroupName
          textexperiencias
          titleexperiencias
          titleexperienciasen
          titleexperienciasde
        }
        modCrossGastronomia {
          animatedgastronomia
          cardsgastronomia {
            label
            labelEn
            labelDe
            title
            titleEn
            titleDe
            image {
              mediaItemUrl
            }
            imagemobile {
              mediaItemUrl
            }
            page {
              ${PAGE_WITH_TRANSLATION}
            }
          }
          classnamegastronomia
          fieldGroupName
          textgastronomia
          titlegastronomia
          titlegastronomiaen
          titlegastronomiade
        }
        modCrossHotel {
          animatedhotel
          cardshotel {
            label
            labelEn
            labelDe
            image {
              mediaItemUrl
            }
            imagemobile {
              mediaItemUrl
            }
            page {
              ${PAGE_WITH_TRANSLATION}
            }
          }
          classnamehotel
          fieldGroupName
          texthotel
          titlehotel
          titlehotelen
          titlehotelde
        }
        modCrossRegaleExperiencia {
          animatedregale
          cardsregale {
            label
            labelEn
            labelDe
            image {
              mediaItemUrl
            }
            imagemobile {
              mediaItemUrl
            }
            page {
              ${PAGE_WITH_TRANSLATION}
            }
          }
          classnameregale
          fieldGroupName
          textregale
          titleregale
          titleregaleen
          titleregalede
        }
        modCrossWellness {
          animatedwellness
          cardswellness {
            label
            labelEn
            labelDe
            image {
              mediaItemUrl
            }
            imagemobile {
              mediaItemUrl
            }
            page {
              ${PAGE_WITH_TRANSLATION}
            }
          }
          classnamewellness
          fieldGroupName
          textwellness
          titlewellness
          titlewellnessen
          titlewellnessde
        }
        modCrossSugerencias {
          classnamesugerencias
          titlesugerencias
          titlesugerenciasen
          titlesugerenciasde
          products {
            product {
              ... on SimpleProduct {
                name
                slug
                
                attributes {
                  nodes {
                    name
                    options
                  }
                }
                type
                price
                image {
                  mediaItemUrl
                }
              }
              ... on GroupProduct {
                name
                slug
                attributes {
                  nodes {
                    name
                    options
                  }
                }
                type
                price
                image {
                  mediaItemUrl
                }
                products {
                  nodes {
                    name
                    productos {
                      subtitulo
                    }
                    attributes {
                      nodes {
                        name
                        options
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
    
      
    `
  );
  return data;
}
