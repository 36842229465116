import fetchApi from "ab-fetch-api";

export default async function updateUser(user, token) {
  const data = await fetchApi(
    `
    mutation UpdateUser($user: UpdateCustomerInput!) {
      updateCustomer(input: $user) { 
        customer {
          id
          email
    
          billing {
            email
          }
        }
      }
    }`,
    {
      variables: { user },
    },
    token
  );
  // console.log("updateUser", data);
  if (data?.isArray && data?.isArray()) {
    return data;
  } else {
    return data?.registerCustomer;
  }
}
