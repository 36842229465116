import Link from "next/link";

import { getTranslation } from "./methods/getTranslation";

const SubMenuConocenos = ({
  activeSection,
  activeSubSection,
  setActiveSubSection,
  menu,
  type,
  isSticky,
  t,
  i18n,
}) => {

  // console.log('SubMenuConocenos', menu)
  return (
    <div
      className={`submenu spa${activeSection == type || activeSubSection == type ? " open" : ""
        }${isSticky ? " sticky" : ""}`}
      onMouseEnter={() => setActiveSubSection(type)}
      onMouseLeave={() => setActiveSubSection(null)}
    >
      <div>
        <div className="left">
          <span>{t("nosotros")}</span>
          <div>
            {menu?.item?.map((itm, index) => {
              if (itm.externallink) {
                return <a
                  href={itm.externallink.url}
                  target={itm.externallink.target}
                >
                  {i18n?.language !== "es"
                    ? getTranslation(itm, "title", {
                      code: i18n.language?.toUpperCase(),
                    }) || itm.externallink.title
                    : itm.externallink.title}
                </a>
              }
              return <Link
                prefetch={false}
                href={getTranslation(itm, "uri", {
                  code: i18n.language?.toUpperCase(),
                })}
                key={`sub-menu-about-${index}`}
              >
                <a onClick={() => setActiveSubSection(null)}>
                  {getTranslation(itm, "title", {
                    code: i18n.language?.toUpperCase(),
                  })}
                </a>
              </Link>
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubMenuConocenos;
