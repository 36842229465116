import { useContext } from "react";
import {
  addCartItems,
  addToCart,
  updateItemQuantities,
  emptyCart,
  applyCoupon,
  removeCoupon,
  getCart,
} from "ab-api-mutations";

import { gtmSendAddToCart } from "ab-gtm";
import { CartStateContext, CartDispatchContext } from "./provider";
import { find } from "lodash-es";

const useCartStateContext = () => {
  const state = useContext(CartStateContext);

  if (state === undefined) {
    throw new Error("State must be defined");
  }

  return state;
};

const useCartDispatchContext = () => {
  const dispatch = useContext(CartDispatchContext);
  const state = useContext(CartStateContext);

  const resetCart = () => {
    emptyCart().then(() => {
      dispatch({
        type: "reset_cart",
      });
    });
  };

  const addItemsToCart = async (cart) => {
    // await emptyCart();
    let response = await addCartItems(
      cart.contents.nodes.map((itm) => ({
        productId: itm?.product?.node?.databaseId || itm?.product?.databaseId,
        quantity: itm.quantity,
      }))
    );

    if (response) {
      dispatch({
        type: "add_item_to_cart",
        payload: response,
      });
    }
  };

  const addItemToCart = async (itm, qty, token, extraData) => {
    console.log("addItemToCart", itm, qty, token, extraData);

    let response = await addToCart(itm.databaseId, qty, token, extraData);

    if (!response) {
      let cartResponse = await getCart(token);
      console.log("cartResponse", cartResponse);
      dispatch({
        type: "add_item_to_cart",
        payload: { ...cartResponse, addingItem: false },
      });
    } else {
      console.log("addItemToCart response", response);

      if (response) {
        dispatch({
          type: "adding_item_to_cart",
          payload: { addingItem: false },
        });

        dispatch({
          type: "add_item_to_cart",
          payload: response,
        });

        gtmSendAddToCart(itm, qty);
      }
    }
  };

  const refreshCart = async () => {
    let token = localStorage.getItem(process.env.AUTH_TOKEN_NAME);
    let cartResponse = await getCart(btoa(token));
    // console.log("refreshCart", cartResponse);
    dispatch({
      type: "add_item_to_cart",
      payload: { ...cartResponse, addingItem: false },
    });
  };

  const handleQtyChange = async (itm, qty, token, extraData) => {
    // console.log("handleQtyChange", qty, itm, state);
    let response = await updateItemQuantities(
      [{ key: itm.key, quantity: qty }],
      null,
      extraData
    );

    // console.log("handleQtyChange", response);

    if (!response) {
      let cartResponse = await getCart(token);
      // console.log("cartResponse", cartResponse);
      dispatch({
        type: "add_item_to_cart",
        payload: { ...cartResponse, addingItem: false },
      });
    } else {
      dispatch({
        type: "adding_item_to_cart",
        payload: response,
      });
    }
  };

  const handleQtyChangePersonalizacion = async (itm, qty, token, extraData) => {
    console.log("handleQtyChangePersonalizacion", state?.contents?.nodes);
    const cartItem = find(
      state?.contents?.nodes,
      (node) => node?.product?.node?.sku === "00605"
    );
    console.log("handleQtyChangePersonalizacion - cartItem", cartItem);

    if (cartItem) {
      const response = await updateItemQuantities(
        [{ key: cartItem.key, quantity: cartItem.quantity + qty }],
        null,
        null
      );
      if (!response) {
        let cartResponse = await getCart(token);
        // console.log("cartResponse", cartResponse);
        dispatch({
          type: "add_item_to_cart",
          payload: { ...cartResponse, addingItem: false },
        });
      } else {
        dispatch({
          type: "adding_item_to_cart",
          payload: response,
        });
      }
    }
  };

  const applyCouponToCart = (code) => {
    applyCoupon(code).then((res) => {
      // console.log("coupon then", res);

      if (res?.cart) {
        dispatch({
          type: "apply_coupon",
          payload: res.cart,
        });
        dispatch({
          type: "error_coupon",
          payload: {
            errorCoupon: "",
          },
        });
      }
      if (!res) {
        // console.log("coupon error", res);

        dispatch({
          type: "error_coupon",
          payload: {
            errorCoupon: "error",
          },
        });
      }
    });
  };

  const removeCouponFromCart = (code) => {
    removeCoupon([code]).then((res) => {
      // console.log("removeCoupon - res", res);
      if (res?.removeCoupons?.cart) {
        dispatch({
          type: "remove_coupon",
          payload: res.removeCoupons.cart,
        });
        dispatch({
          type: "error_coupon",
          payload: {
            errorCoupon: "",
          },
        });
      }
    });
  };

  return {
    ...state,
    addItemsToCart,
    addItemToCart,
    handleQtyChange,
    handleQtyChangePersonalizacion,
    resetCart,
    applyCouponToCart,
    removeCouponFromCart,
    refreshCart,
  };
};

const useCartContext = () => {
  return [useCartStateContext(), useCartDispatchContext()];
};

export { useCartContext };
