import { GROUPED_PRODUCT_INFO } from "./grouped-product-info";
import { SIMPLE_PRODUCT_INFO } from "./simple-product-info";

export const PRODUCT_INFO = `
        databaseId
        image {
          mediaItemUrl
        }
        galleryImages {
          nodes {
            mediaItemUrl      
          }
        }
        name
        slug
        sku
        description
        attributes {
          nodes {
            name
            options
          }
        }
        type
        productCategories {
          nodes {
            name
          }
        }
        configuradorEstuches {
          ... on Product_Configuradorestuches {
            numeroBotellas
            numeroProductosDeLaFinca
            vinos {
              producto {
                ... on Product {
                  databaseId
                  image {
                    mediaItemUrl
                  }
                  galleryImages {
                    nodes {
                      mediaItemUrl      
                    }
                  }
                  name
                  slug
                  sku
                  description
                  attributes {
                    nodes {
                      name
                      options
                    }
                  }
                  type
                }
                ... on SimpleProduct {
                  ${SIMPLE_PRODUCT_INFO}
                }
              }
            }
            productosDeLaFinca {
              producto {
                ... on Product {
                  databaseId
                  image {
                    mediaItemUrl
                  }
                  galleryImages {
                    nodes {
                      mediaItemUrl      
                    }
                  }
                  name
                  slug
                  sku
                  description
                  attributes {
                    nodes {
                      name
                      options
                    }
                  }
                  type
                }
                ... on SimpleProduct {
                  ${SIMPLE_PRODUCT_INFO}
                }
              }
            }
            imagenTirilla {
              mediaItemUrl
            }
            imagenTarjeton {
              mediaItemUrl
            }
          }
        }
        ... on SimpleProduct {
          ${SIMPLE_PRODUCT_INFO}
        }
        ... on GroupProduct {
          ${GROUPED_PRODUCT_INFO}
        }
        configuradorEbox {
          ... on Product_Configuradorebox {
            numeroEstancias
            numeroExperiencias
            upselling
            estancias {
              producto {
                ... on Product {
                  databaseId
                  image {
                    mediaItemUrl
                  }
                  galleryImages {
                    nodes {
                      mediaItemUrl
                    }
                  }
                  name
                  slug
                  sku
                  description
                  attributes {
                    nodes {
                      name
                      options
                    }
                  }
                  type
                }
                ... on SimpleProduct {
                  ${SIMPLE_PRODUCT_INFO}
                }
              }
            }
            experiencias {
              producto {
                ... on Product {
                  databaseId
                  image {
                    mediaItemUrl
                  }
                  galleryImages {
                    nodes {
                      mediaItemUrl
                    }
                  }
                  name
                  slug
                  sku
                  description
                  attributes {
                    nodes {
                      name
                      options
                    }
                  }
                  type
                }
                ... on SimpleProduct {
                  ${SIMPLE_PRODUCT_INFO}
                }
              }
            }
            fijas {
              producto {
                ... on Product {
                  databaseId
                  image {
                    mediaItemUrl
                  }
                  galleryImages {
                    nodes {
                      mediaItemUrl
                    }
                  }
                  name
                  slug
                  sku
                  description
                  attributes {
                    nodes {
                      name
                      options
                    }
                  }
                  type
                }
                ... on SimpleProduct {
                  ${SIMPLE_PRODUCT_INFO}
                }
              }
            }
          }
        }

        `;
