import {
  ModalProvider,
  ModalStateContext,
  ModalDispatchContext,
} from "./provider";
import { useModalContext } from "./useModalContext";
import PopUp from "./PopUp";
export {
  ModalProvider,
  ModalStateContext,
  ModalDispatchContext,
  useModalContext,
  PopUp,
};
