import fetchApi from "ab-fetch-api";

export async function getPageStaticPaths(lang = "ES") {
  if (lang == "EN") {
    const data = await fetchApi(
      `
      {
        pages(first: 1000, where: {parentIn: [20037, 20210, 20163, 20238, 20251, 20567, 20406, 20375, 20032, 20039]}) {
          nodes {
            ... on Page {
              title
              databaseId
              slug
              uri
            }
          }
        }
      }
      
  `
    );
    // console.log("getCategories - inner ", data);
    return data;
  } else if (lang == "DE") {
    const data = await fetchApi(
      `
        {
          pages(first: 1000, where: {parentIn: [28292, 28362, 28343, 28355, 28361, 28351, 28342, 28322, 28283, 28303,  48960, 49117, 49097, 49097,49095, 49074, 49054, 49024, 48946, 48976]}) {
            nodes {
              ... on Page {
                title
                databaseId
                slug
                uri
              }
            }
          }
        }
      `
    );
    // console.log("getCategories - inner ", data);
    return data;
  } else {
    const data = await fetchApi(
      `
    {
        pages(first: 1000, where: {parentIn: [13658, 13865, 13845, 13831, 13817, 13811, 13809, 13805, 13787, 13754, 13750]}) {
          nodes {
            ... on Page {
              title
              databaseId
              slug
              uri
            }
          }
        }
      }
    
  `
    );
    // console.log("getCategories - inner ", data);
    return data;
  }
}
