export function gtmSendUserId(userId) {
  let pageEvent = {
    user_id: userId,
  };

  window && window.dataLayer && window.dataLayer.push(pageEvent);
}

export function gtmSendComprobarDisponibilidad(
  startDate,
  endDate,
  guests,
  rooms
) {

  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: "comprobar disponibilidad",
      tipo_evento: "interaccion",
      fecha_llegada: startDate,
      fecha_salida: endDate,
      num_huespedes: guests,
      num_habitaciones: rooms,
      uid: "931287",
    });
}

export function gtmSendBotonesHeader(page, boton) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: "clic header",
      tipo_evento: "interaccion",
      directorio: page,
      boton_seleccionado: boton,
      uid: "931287",
    });
}

export function gtmSendContact() {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: "contacto",
      tipo_evento: "conversion",
      uid: "931287",
    });
}
export async function gtmSendContactExperiencia(data) {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      'event': 'generate_lead',
      'lead_type': data.tipo_lead,
      "category": data.category,
      'fecha_entrada': data.fecha_de_entrada,
      'horario': data.horario,
      'idioma': data.idioma_de_preferencia,
      'kids': data.numero_de_niños,
      'adult': data.numero_de_adultos,
      'pais': data.pais,
      'nombre': await sha256(data.nombre), // Hasheado
      'apellido': await sha256(data.apellido), // Hasheado
      'email': await sha256(data.email), // Hasheado
    });
}
export function gtmSendRegister() {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: "registro",
      tipo_evento: "conversion",
      uid: "931287",
    });
}

export function gtmSendLogin() {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: "login",
      tipo_evento: "interaccion",
      uid: "931287",
    });
}

export function gtmSendCookie() {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: "cookieprivacy",
    });
}

export function gtmProductImpressions(products, location) {
  let gtmProducts = products.map((itm, index) => ({
    name: itm.name,
    id: itm.databaseId?.toString(),
    price: parseFloat(itm.price)
      ?.toFixed(2)
      ?.toString(),
    list: itm.location,
    position: index,
  }));
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      ecommerce: {
        currencyCode: "EUR", // Local currency is optional.
        impressions: gtmProducts,
      },
    });
}

export function gtmSendAddToCart(product, qty) {
  window && window.dataLayer && window.dataLayer.push({ ecommerce: null });

  // const priceformatted = parseFloat(product?.price)
  //   ?.toFixed(2);

  // console.log('gtmSendAddToCart priceformatted',
  //   priceformatted);

  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: "addToCart",
      ecommerce: {
        currencyCode: "EUR",
        add: {
          // 'add' actionFieldObject measures.
          products: [
            {
              //  adding a product to a shopping cart.
              name: product?.name,
              id: product?.databaseId?.toString(),
              price: parseFloat(product?.price)
                ?.toFixed(2)
                ?.toString(),
              brand: "Abadia",
              category: "Wine",
              variant: product?.attributes?.nodes?.length
                ? product?.attributes?.nodes[0]?.name +
                " " +
                product?.attributes?.nodes[0]?.options[0]
                : "",
              quantity: qty,
            },
          ],
        },
      },
    });
}

export function gtmSendRemoveFromCart(product, qty) {
  window && window.dataLayer && window.dataLayer.push({ ecommerce: null });
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: "removeFromCart",
      ecommerce: {
        remove: {
          // 'remove' actionFieldObject measures.
          products: [
            {
              //  removing a product to a shopping cart.
              name: product?.name,
              id: product?.databaseId?.toString(),
              price: parseFloat(product?.price)
                ?.toFixed(2)
                ?.toString(),
              brand: "Abadia",
              category: "Wine",
              variant: product?.attributes?.nodes?.length
                ? product?.attributes?.nodes[0]?.name +
                " " +
                product?.attributes?.nodes[0]?.options[0]
                : "",
              quantity: qty,
            },
          ],
        },
      },
    });
}

export function gtmSendCheckoutStep0(products) {
  window && window.dataLayer && window.dataLayer.push({ ecommerce: null });
  window.dataLayer &&
    window.dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: "Identification" },
          products: products.map((node) => ({
            name: node?.product?.node?.name,
            id: node?.product?.node?.databaseId?.toString(),
            price: formatPrice(node?.product?.node?.price),
            brand: "Abadia",
            category: "Wine",
            variant: node?.product?.node?.attributes?.nodes?.length
              ? node?.product?.node?.attributes?.nodes[0]?.name +
              " " +
              node?.product?.node?.attributes?.nodes[0]?.options[0]
              : "",
            quantity: node?.quantity,
          })),
        },
      },
    });
}

const formatPrice = (price) => {
  const priceFormatted = parseFloat(price?.replace(".", "")?.replace(",", "."))
    ?.toFixed(2)
    ?.toString();
  console.log("formatPrice", priceFormatted);
  return priceFormatted;
};

export function gtmSendCheckoutStep1(selected) {
  window && window.dataLayer && window.dataLayer.push({ ecommerce: null });
  window.dataLayer &&
    window.dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 2, option: "Shipping/Billing Details" },
        },
      },
    });

  window && window.dataLayer && window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: "checkoutOption",
      ecommerce: {
        checkout_option: {
          actionField: { step: 1, option: { selected: selected } },
        },
      },
    });
}

export function gtmSendCheckoutStep2() {
  window && window.dataLayer && window.dataLayer.push({ ecommerce: null });
  window.dataLayer &&
    window.dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 3, option: "Payment Method" },
        },
      },
    });
}

export function gtmSendCheckoutStep3(selected) {
  window && window.dataLayer && window.dataLayer.push({ ecommerce: null });
  window.dataLayer &&
    window.dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 4, option: "Confirm Purchase" },
        },
      },
    });

  window && window.dataLayer && window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: "checkoutOption",
      ecommerce: {
        checkout_option: {
          actionField: { step: 4, option: { payment: selected } },
        },
      },
    });

  // console.log("GTM Step 4 ", selected);
}

export function gtmSendPurchase(order) {
  window && window.dataLayer && window.dataLayer.push({ ecommerce: null });
  window.dataLayer &&
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: order?.orderNumber, // Transaction ID. Required for purchases and refunds.
            affiliation: "Abadía Shop",
            revenue: parseFloat(order?.total)
              ?.toFixed(2)
              ?.toString(), // Total transaction value (incl. tax and shipping)
            tax: parseFloat(order?.totalTax)
              ?.toFixed(2)
              ?.toString(),
            shipping: parseFloat(order?.shippingTotal)
              ?.toFixed(2)
              ?.toString(),
          },

          products: order?.lineItems?.nodes.map((node) => ({
            name: node?.product?.name,
            id: node?.product?.databaseId?.toString(),
            price: formatPrice(node?.product?.price),
            brand: "Abadia",
            category: "Wine",
            variant: node?.product?.attributes?.nodes?.length
              ? node?.product?.attributes?.nodes[0]?.name +
              " " +
              node?.product?.attributes?.nodes[0]?.options[0]
              : "",
            quantity: node?.quantity,
          })),
        },
      },
    });
  // console.log(" GTM gtmSendPurchase ", order?.orderNumber);
}
async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}