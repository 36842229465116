import fetchApi from "ab-fetch-api";

export async function getCategories(lang = "ES") {
  const data = process.env.STAGE == "dev" ?

    await fetchApi(
      `
    query getCategories($lang: LanguageCodeFilterEnum) {
      pages(first: 100, where: {parent: 0, notIn: [2, 48714, 3, 49009, 8,48904, 9, 48906, 10, 48982, 13302, 49441, 13891, 49006, 22202,21559, 21555,48754,15026,49001, 19186, 49045, 19196, 19205,48911, 19212, 20665, 48926, 20669, 49050, 20671, 51864, 20673,49011, 21520, 21020, 21018, 20667, 20658, 20652, 20633, 20516, 20321,48904, 21522, 20327, 20648, 20098, 13400, 21269,48980, 21271, 21424, 23384, 28273, 51864, 51866, 125098, 129347, 134038, 134043, 27202, 51869, 27296, 51874, 28320], language: $lang}) {
        nodes {
          ... on Page {
            title
            databaseId
            slug
            parentId
            uri
          }
        }
      }
    }    
    
  `,
      {
        variables: {
          lang,
        },
      }
    )
    :

    await fetchApi(
      `
    query getCategories($lang: LanguageCodeFilterEnum) {
      pages(first: 100, where: {parent: 0, notIn: [2, 22415, 28437, 3, 8, 9, 10, 13302, 13891, 22202, 49006,21559, 21555,48754,15026, 19186, 19196, 19205, 19212, 20665, 20669, 20671, 20673, 21520, 21462, 21020, 21018, 20667, 20658, 20652, 20633, 20516, 20321, 21522, 20327, 21063,21065,21071, 20648, 20098,29591, 13400, 21269, 21271, 21863,21865, 21424, 23384, 24557, 21750, 28249, 28253, 28369,28305, 28306, 28313, 28317,28331, 28316, 28257, 28273, 28333, 28299, 28318, 28264, 28437, 34737, 51864, 51866 ,48784], language: $lang}) {
        nodes {
          ... on Page {
            title
            databaseId
            slug
            parentId
            uri
          }
        }
      }
    }    
    
  `,
      {
        variables: {
          lang,
        },
      }
    );
  // console.log("getCategories - inner ", data);
  return data;
}
