import isProductInCart from "./isProductInCart";
import calculateSalePrice from "./calculateSalePrice";
import getFloatVal from "./getFloatVal";
import createNewProduct from "./createNewProduct";
/**
 * Get updated products array
 * Update the product if it exists else,
 * add the new product to existing cart
 */
const getUpdatedProducts = (
  existingProductsInCart,
  product,
  qtyToBeAdded,
  newQty = false
) => {
  // Get product ID
  let productId =
    product.databaseId !== undefined ? product.databaseId : product.productId;

  // Check if the product already exits in the cart.
  const productExitsIndex = isProductInCart(
    existingProductsInCart,
    productId,
    product.variationId
  );
  // If product exits ( index of that product found in the array ), update the product quantity and totalPrice

  if (-1 < productExitsIndex) {
    let updatedProducts = existingProductsInCart;
    let updatedProduct = updatedProducts[productExitsIndex];

    // If have new qty of the product available, set that else add the qtyToBeAdded

    updatedProduct.qty = newQty
      ? parseInt(newQty)
      : parseInt(updatedProduct.qty + qtyToBeAdded);

    const subtotal = parseFloat(
      (updatedProduct.price * updatedProduct.qty).toFixed(2)
    );

    updatedProduct.subtotalPrice = subtotal;
    updatedProduct.totalPrice = subtotal;

    return updatedProducts;
  } else {
    // If product not found push the new product to the existing product array.
    let productPrice = getFloatVal(product.price);
    const newProduct = createNewProduct(product, productPrice, qtyToBeAdded);
    existingProductsInCart.push(newProduct);

    return existingProductsInCart;
  }
};

export default getUpdatedProducts;
