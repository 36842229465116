import React, { useReducer } from "react";


const defaultState = {
  giftMsg: null,
  giftName: null,
  giftLastName: null,
  itemsEbox: [],
};

import reducer from "./reducer";

const EboxStateContext = React.createContext();
const EboxDispatchContext = React.createContext();

const EboxProvider = ({ children }) => {
  // const [state, dispatch] = useImmer({ ...defaultState });
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
  });


  // console.log("EboxProvider - state - ", state);

  return (
    <EboxStateContext.Provider value={state}>
      <EboxDispatchContext.Provider value={dispatch}>
        {children}
      </EboxDispatchContext.Provider>
    </EboxStateContext.Provider>
  );
};

export { defaultState, EboxProvider, EboxStateContext, EboxDispatchContext };
