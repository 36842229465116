import { useState, useEffect, useRef } from "react";
import { useTranslation } from "next-i18next";
import Text from "ab-text";
import QuantityPicker from "ab-quantity-picker";

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb && cb();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Cantidad = ({ label, unit, units, value, onChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setIsVisible(false);
  });
  const { t, i18n } = useTranslation("common");
  // console.log("ab-reservar cantidad", i18n, t("rooms"), t("huespedes"));
  return (
    <div className="cantidad">
      {isVisible ? (
        <div className="picker" ref={wrapperRef}>
          <Text element="span" className="title">
            {`${t("numero-de")} ${units}`}
          </Text>
          <QuantityPicker
            noSuffix
            quantity={value}
            setQuantity={onChange}
            i18n={i18n}
          />
        </div>
      ) : null}
      <span onClick={() => setIsVisible(!isVisible)}>
        {value == 0
          ? label
          : `${value} ${value > 1 ? units.toLowerCase() : unit.toLowerCase()}`}
      </span>
    </div>
  );
};

export default Cantidad;
