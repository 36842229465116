import fetchApi from "ab-fetch-api";

export async function getSubCategories(lang = "ES") {
  if (lang == "EN") {
    const data = await fetchApi(
      `
      {
        pages(first: 1000, where: {parentIn: [20028,20008,20654, 20369, 20024, 20123]}) {
          nodes {
            ... on Page {
              title
              databaseId
              slug
              uri
              translations {
                databaseId
              }
            }
          }
        }
      }
    `
    );
    // console.log("getCategories - inner ", data);
    return data;
  } else if (lang == "DE") {
    const data = await fetchApi(
      `
        {
          pages(first: 1000, where: {parentIn: [28280,28334,28245, 28319, 28274, 28268, 48941, 49052, 49440, 49013, 48928,48918]}) {
            nodes {
              ... on Page {
                title
                databaseId
                slug
                uri
                translations {
                  databaseId
                }
              }
            }
          }
        }
      `
    );
    // console.log("getCategories - inner ", data);
    return data;
  } else {
    const data = await fetchApi(
      `
      {
        pages(first: 1000, where: {parentIn: [13427, 13380, 13353, 13327,13311,13877]}) {
          nodes {
            ... on Page {
              title
              databaseId
              slug
              uri
            }
          }
        }
      }
    `
    );
    // console.log("getCategories - inner ", data);
    return data;
  }
}
