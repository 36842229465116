import { filter, findIndex } from "lodash-es";
import { useContext } from "react";

import { EboxStateContext, EboxDispatchContext } from "./provider";

const useEboxStateContext = () => {
  const state = useContext(EboxStateContext);

  if (state === undefined) {
    throw new Error("State must be defined");
  }

  return state;
};

const useEboxDispatchContext = () => {
  const dispatch = useContext(EboxDispatchContext);
  const state = useContext(EboxStateContext);

  const resetEbox = () => {
    console.log(" resetEbox - ", resetEbox);
    dispatch({
      type: "reset_ebox",
    });
  };

  const addItemsToEbox = async (items) => {
    let newItems = items.map((itm) => ({
      productId:
        itm?.producto?.databaseId ||
        itm?.product?.node?.databaseId ||
        itm?.databaseId,
      quantity: 1,
      sku: itm?.producto?.sku || itm?.product?.node?.sku || itm?.sku,
      price: itm?.producto?.price || itm?.product?.node?.price || itm?.price,
      name: itm?.producto?.name || itm?.product?.node?.name || itm?.name,
    }));
    console.log(" addItemsToEbox - ", newItems);
    let addItems = [];
    newItems.forEach((nuevo) => {
      console.log("newItems addItemsToEbox - ", newItems);
      const index = findIndex(
        state?.itemsEbox,
        (item) => item?.productId == nuevo?.productId
      );
      if (index == -1) {
        console.log("newItems nuevo - ", nuevo);
        // addItemToEbox(nuevo);
        addItems.push(nuevo);
      }
    });
    dispatch({
      type: "add_items_to_ebox",
      payload: {
        itemsEbox: [...state?.itemsEbox, ...addItems],
      },
    });
    // dispatch({
    //   type: "add_items_to_ebox",
    //   payload: {
    //     itemsEbox: [...state?.itemsEbox, ...newItems]
    //   },
    // });
  };

  const addItemToEbox = async (itm) => {
    console.log(" addItemToEbox - ", itm);
    const productId =
      itm?.product?.node?.databaseId || itm?.databaseId || itm?.productId;
    const sku = itm?.product?.node?.sku || itm?.sku;
    const price = itm?.product?.node?.price || itm?.price;
    const name = itm?.producto?.name || itm?.product?.node?.name || itm?.name;

    const index = findIndex(
      state?.itemsEbox,
      (item) => item?.productId == productId
    );
    if (index == -1) {
      dispatch({
        type: "add_item_to_ebox",
        payload: {
          itemsEbox: [
            ...state?.itemsEbox,
            {
              productId: productId,
              quantity: 1,
              sku: sku,
              price: price,
              name: name,
            },
          ],
        },
      });
    }
  };

  const removeItemFromEbox = async (itm, addItem) => {
    const productId = itm?.product?.node?.databaseId || itm?.databaseId;

    const aux = filter(state?.itemsEbox, (item) => {
      // console.log("  removeItemFromEbox - item", item?.productId);
      // console.log("  removeItemFromEbox - productId", productId);
      return item?.productId !== productId;
    });

    console.log("  removeItemFromEbox", aux);

    console.log("  -----------removeItemFromEbox----------");
    if (addItem) {
      console.log("  removeItemFromEbox --- Additem", addItem);

      const addItemProductId =
        addItem?.product?.node?.databaseId ||
        addItem?.databaseId ||
        addItem?.productId;
      const sku = addItem?.product?.node?.sku || addItem?.sku;
      const price = addItem?.product?.node?.price || addItem?.price;
      const name =
        addItem?.producto?.name ||
        addItem?.product?.node?.name ||
        addItem?.name;
      const index = findIndex(
        state?.itemsEbox,
        (item) => item?.productId == addItemProductId
      );
      if (index == -1) {
        dispatch({
          type: "remove_item",
          payload: {
            itemsEbox: [
              ...aux,
              {
                productId: addItemProductId,
                quantity: 1,
                sku: sku,
                price: price,
                name: name,
              },
            ],
          },
        });
      }
    } else {
      dispatch({
        type: "remove_item",
        payload: {
          itemsEbox: aux,
        },
      });
    }
  };

  const setDedicatoria = async (dedicatoria) => {
    dispatch({
      type: "set_dedicatoria",
      payload: {
        giftMsg: dedicatoria?.giftMsg,
        giftName: dedicatoria?.giftName,
        giftLastName: dedicatoria?.giftLastName,
      },
    });
  };
  console.log("useEboxContext", state);
  return {
    ...state,
    addItemsToEbox,
    addItemToEbox,
    resetEbox,
    removeItemFromEbox,
    setDedicatoria,
  };
};

const useEboxContext = () => {
  return [useEboxStateContext(), useEboxDispatchContext()];
};

export { useEboxContext };
