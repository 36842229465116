import fetchApi from "ab-fetch-api";
import { ORDER_INFO } from "ab-api-fragments";

export default async function checkout(input, token) {
  let query = `
  mutation CHECKOUT_MUTATION($input: CheckoutInput!) {
    checkout(input: $input) {
      redirect
      result
      order {
        ${ORDER_INFO}
      }
      customer {
        id
        email
        billing {
          email
        }
        sessionToken
      }
    }
  }
    `;

  const data = await fetchApi(
    query,
    {
      variables: input,
    },
    token
  );

  if (data.status === 500) {
    return data;
  } else {
    return data?.checkout;
  }
}
