import {
  getAuthToken,
  getRefreshToken,
  hasExpiredAuthToken,
  encodeToken,
} from "ab-functions-auth";

import { refreshJwtAuthToken } from "ab-api-mutations";

export async function fetchAPI(query, { variables, operationName } = {}) {
  const headers = { "Content-Type": "application/json" };

  // console.log("fetchAPI ", query);

  const res = await fetch(process.env.WORDPRESS_API_URL, {
    method: "POST",
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  }).catch((err) => {
    // console.log("fetchAPI - err", err);
  });
  // console.log("fetchAPI - res", res);
  // const json = await res?.json();
  const json = res?.ok ? await res?.json() : {};
  if (json?.errors) {
    console.error(json?.errors);
    return {
      status: 500,
      errors: json?.errors,
    };
  }
  return json?.data;
}

export const fetchApiFromClient = async (
  query,
  { variables, operationName } = {}
) => {
  let headers = { "Content-Type": "application/json" };
  let token = getAuthToken();
  // console.log("Erre", token);
  let waitRefresh = token;
  if (token) {
    const refreshToken = getRefreshToken();

    if (hasExpiredAuthToken(token) && !query?.includes("refreshJwtAuthToken")) {
      waitRefresh = await refreshJwtAuthToken(refreshToken);

      // console.log("waitRefresh refreshJwtAuthToken", waitRefresh);

      if (waitRefresh?.status && waitRefresh?.status === 500) {
        headers["Authorization"] = "Bearer " + token;
      } else {
        const newToken = waitRefresh?.data?.authToken;
        // console.log("Refresh else - ", newToken);
        headers["Authorization"] = "Bearer " + newToken;

        if (localStorage.getItem(process.env.AUTH_TOKEN_NAME)) {
          localStorage.setItem(
            process.env.AUTH_TOKEN_NAME,
            encodeToken(newToken)
          );
        } else if (sessionStorage.getItem(process.env.AUTH_TOKEN_NAME)) {
          sessionStorage.setItem(
            process.env.AUTH_TOKEN_NAME,
            encodeToken(newToken)
          );
        }
      }
    } else {
      if (!query?.includes("refreshJwtAuthToken")) {
        headers["Authorization"] = "Bearer " + token;
      }
    }

    // console.log("waitRefresh", token);
    // if (!query?.includes("refreshJwtAuthToken")) {
    //   headers["Authorization"] = "Bearer " + waitRefresh;
    // }
  }

  const session =
    typeof window === "undefined" ? null : localStorage.getItem("woo-session");

  if (session) {
    headers["woocommerce-session"] = `Session ${session}`;
  } else {
    // console.log("DOESNT HAVE SESSION");
  }
  // console.log(
  //   "fetchApiFromClient - ",
  //   `${
  //     process.env.LOCAL ? "http://localhost:3000/api" : process.env.SERVER_API
  //   }/fetchData`
  // );
  const res = await fetch(process.env.WORDPRESS_API_URL, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      query,
      variables,
      operationName,
    }),
  });

  const data = await res?.json();
  if (
    data?.errors &&
    (query?.includes("LoginUser") || query?.includes("RegisterUser"))
  ) {
    return {
      status: 500,
      errors: data.errors,
    };
  }

  if (data?.errors) {
    return {
      status: 500,
      errors: "Ha ocurrido un error inesperado",
    };
  }

  // console.log("DATA RESULT", data);

  const resJson = data;
  const newSession = res.headers.get("woocommerce-session");

  if (process.browser && newSession) {
    // Remove session data if session destroyed.
    if ("false" === newSession) {
      localStorage.removeItem("woo-session");

      // Update session new data if changed.
    } else if (localStorage.getItem("woo-session") !== newSession) {
      // console.log("woocommerce-session", newSession);
      localStorage.setItem("woo-session", newSession);
    }
  }

  if (resJson.errors) {
    return {
      status: 500,
      errors: resJson.errors,
    };
    //throw new Error('Failed to fetch API')
  }

  return resJson.data;
};

export async function fetchApi(query, { variables } = {}, token = null) {
  // console.log("fetchApi - browser or server", typeof window === "undefined");
  if (typeof window === "undefined") {
    return await fetchAPI(query, { variables });
  } else {
    return await fetchApiFromClient(query, { variables }, token);
  }
}

export default fetchApi;
