import Link from "next/link";
import Image from "next/image";

import { getTranslation } from "./methods/getTranslation";

const SubMenuGastronomia = ({
  activeSection,
  activeSubSection,
  setActiveSubSection,
  menu,
  type,
  isSticky,
  t,
  i18n,
}) => {
  // console.log("Restaurante", menu);
  return (
    <div
      className={`submenu gastronomia${activeSection == type || activeSubSection == type ? " open" : ""
        }${isSticky ? " sticky" : ""}`}
      onMouseEnter={() => setActiveSubSection(type)}
      onMouseLeave={() => setActiveSubSection(null)}
    >
      <div>
        <div className="left">
          {menu?.restaurante.map((itm, index) => (
            <Link
              prefetch={false}
              href={getTranslation(itm, "uri", {
                code: i18n.language?.toUpperCase(),
              })}
              key={`sub-menu-gastronomia-${index}`}
            >
              <a onClick={() => setActiveSubSection(null)}>
                <span>
                  {getTranslation(itm, "title", {
                    code: i18n.language?.toUpperCase(),
                  })}
                </span>
                <p>
                  {i18n.language == "en"
                    ? itm.descripcionEn || itm.descripcion
                    : i18n.language == "de"
                      ? itm.descripcionDe || itm.descripcion
                      : itm.descripcion}
                </p>
                <div className="image">
                  <Image
                    layout="fill"
                    src={itm.imagen.mediaItemUrl || '/images/404.png'}
                    alt={getTranslation(itm, "title", {
                      code: i18n.language?.toUpperCase(),
                    })}
                  />
                </div>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubMenuGastronomia;
