import fetchApi from "ab-fetch-api";
import { ORDER_INFO } from "ab-api-fragments";

export async function getCustomerOrders(variables, token) {
  const data = await fetchApi(
    `
    query getCustomerOrder($id: ID, $first: Int, $last: Int, $before: String, $after: String) {
      customer(id: $id)  {
        orders(first: $first, last: $last, before: $before, after: $after) {
          nodes {
            ${ORDER_INFO}
          }
          pageInfo {
            endCursor,
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    }
    
        
  `,
    {
      variables,
    },
    token
  );
  return data;
}
