import { useContext } from "react";
import { omit } from "lodash-es";
import { useRouter } from "next/router";

import { checkout, updateUser } from "ab-api-mutations";
import { useUserContext } from "ab-context-user";

import { CheckoutStateContext, CheckoutDispatchContext } from "./provider";

const useCheckoutStateContext = () => {
  const state = useContext(CheckoutStateContext);

  if (state === undefined) {
    throw new Error("State must be defined");
  }

  return state;
};

const useCheckoutDispatchContext = () => {
  const router = useRouter();
  const dispatch = useContext(CheckoutDispatchContext);
  const state = useContext(CheckoutStateContext);

  const [userState, userDispatch] = useUserContext();
  const { id } = userState;
  // const { updateUser } = userDispatch;

  const nextStep = (step) => {
    dispatch({
      type: "next_step",
      payload: step,
    });
  };

  const setStep0 = (email, password, selected, step) => {
    if (!id) {
      updateUser({
        email,
        billing: {
          email,
        },
      }).then((res) => {
        // console.log("updateUser", res);
      });
    }

    dispatch({
      type: "set_step_0",
      payload: {
        email,
        password,
        selected,
        step,
      },
    });
  };

  const setStep1 = (values, contents) => {
    dispatch({
      type: "set_step_1",
      payload: {
        checkout: values,
        contents,
      },
    });
  };

  const back = () => {
    dispatch({
      type: "step_back",
    });
  };

  const setCheckout = (cart, method, authToken, omitCreateAccount) => {
    // console.log("setCheckout", cart, method, authToken);
    let input = {
      billing: {
        ...omit(cart.billing, [
          "shippingSameAsBilling",
          "shippingaddress1",
          "shippingaddress2",
          "shippingcity",
          "shippingcountry",
          "shippingstate",
          "shippingpostcode",
          "shippingphone",
          "shippingfirstName",
          "shippinglastName",
          "isGift",
          "isOfAge",
          "dni",
          "giftMsg",
          "shippingdni",
          "cif",
          "isOfAge",
        ]),
        email: cart.user.email,
      },
      metaData: cart.metaData,
      paymentMethod: method == "paypal" ? "ppcp-gateway" : method,
      shippingMethod: cart.shippingMethod,
      isPaid: !!cart?.metaData?.find(
        (itm) => itm.key == "_payment_paypal_order"
      )?.value,
      customerNote: cart.customerNote,
    };
    // console.log("input ", input);
    if (cart?.user?.password && !omitCreateAccount) {
      input.account = {
        password: cart?.user?.password,
        username: cart?.user?.email
          .replace("@", "")
          .replace(".", "")
          .replace("+", ""),
      };
    }
    // console.log("input con user", input);
    if (cart.billing.shippingSameAsBilling) {
      input.shipping = omit(cart.billing, [
        "shippingSameAsBilling",
        "shippingaddress1",
        "shippingaddress2",
        "shippingcity",
        "shippingstate",
        "shippingpostcode",
        "shippingphone",
        "shippingfirstName",
        "shippinglastName",
        "giftMsg",
        "isGift",
        "isOfAge",
        "dni",
        "cif",
        "isOfAge",
      ]);
      // console.log("input same shipping", input);
    } else {
      input.shipToDifferentAddress = !cart.billing.shippingSameAsBilling;
      input.shipping = {
        firstName: cart?.billing.shippingfirstName,
        lastName: cart?.billing.shippinglastName,
        phone: cart?.billing.shippingphone,
        address1: cart?.billing.shippingaddress1,
        address2: cart?.billing.shippingaddress2,
        city: cart?.billing.shippingcity,
        state: cart?.billing.shippingstate,
        country: cart?.billing.country,
        postcode: cart?.billing.shippingpostcode,
      };
      // console.log("input shipping different", input);
    }
    // console.log("input before mutation", input);
    return checkout(
      {
        input,
      },
      authToken
    ).then((e) => {
      // console.log("checkoutRespose", e);

      dispatch({
        type: "method_selected",
        payload: {
          method,
          response: e.order,
        },
      });
    });
  };

  const removeCheckout = () => {
    dispatch({
      type: "reset_checkout",
    });
  };

  return {
    ...state,
    setStep0,
    setStep1,
    setCheckout,
    back,
    nextStep,
    removeCheckout,
  };
};

const useCheckoutContext = () => {
  return [useCheckoutStateContext(), useCheckoutDispatchContext()];
};

export { useCheckoutContext };
