import { SIMPLE_PRODUCT_INFO } from "./simple-product-info";

export const GROUPED_PRODUCT_INFO = `
price
image {
  mediaItemUrl
}
...on GroupProduct {
  groupedProductIngles {
    description
  }
  groupedProductAleman {
    descriptionAleman
  }
}
products(where: {
  stockStatus: IN_STOCK,
  visibility: VISIBLE,
  },
  first: 50) {
  nodes {
    databaseId
    name
    ... on SimpleProduct {
      ${SIMPLE_PRODUCT_INFO}
    }
    productos {
      imagenDescripcion {
        mediaItemUrl
      }
      notasDeCata
      imagenCata {
        mediaItemUrl
      }
      notasAnada
      imagenAnada {
        mediaItemUrl
      }
      infoAddicional {
        variedad
        conservacion
        denominacionDeOrigen
        vida
        alergenos
        gradoAlcoholico
        informacionNutricional
        elaboradoYEmbotelladoPor
        ingredientes {
          target
          url
          title
        }
      }
      premios {
        nombre
        imagen {
          mediaItemUrl
        }
      }
    }
    productos_ingles {
      notasAnadaIngles
      notasDeCataIngles
      infoAddicionalIngles {
        variedad
        conservacion
        denominacionDeOrigen
        vida
        alergenos
        gradoAlcoholico
        informacionNutricional
        elaboradoYEmbotelladoPor
        ingredientes {
          target
          url
          title
        }
      }
      premiosIngles {
        nombre
        imagen {
          mediaItemUrl
        }
      }
    }
    productos_aleman {
      notasAnadaAleman
      notasDeCataAleman
      infoAddicionalAleman {
        variedad
        conservacion
        denominacionDeOrigen
        vida
        alergenos
        gradoAlcoholico
        informacionNutricional
        elaboradoYEmbotelladoPor
        ingredientes {
          target
          url
          title
        }
      }
      premiosAleman {
        nombre
        imagen {
          mediaItemUrl
        }
      }
    }
    attributes {
      nodes {
        name
        options
      }
    }
  }
}
        `;
