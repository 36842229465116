import fetchApi from "ab-fetch-api";

export default async function resetUserPassword(email, key, password) {
  const data = await fetchApi(
    `
      mutation ResetUserPassword($email: String!, $key: String!, $password: String) {
        resetUserPassword(input: {login: $email, key: $key, password: $password}) {
          clientMutationId
        }
      }`,
    {
      variables: { email, key, password },
    }
  );
  // console.log("paswordreset", data);
  if (data?.status === 500) {
    return data.errors;
  } else {
    return data?.resetUserPassword;
  }
}
