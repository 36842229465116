import React from "react";
import { useTranslation } from "next-i18next";
const ModalContainer = ({ state, dispatch }) => {
  const { t } = useTranslation("common");
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 9999,
        transition: "all 500ms",
        opacity: state?.showModal ? 1 : 0,
        pointerEvents: state?.showModal ? "initial" : "none",
        display: state?.showModal ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
      }}
      id={"modal-container"}
      onClick={(e) => {
        dispatch({
          type: "set_showModal",
          payload: { showModal: false },
        });
        document.body.style.overflow = "initial";

        e.stopPropagation();
      }}
      role="button"
      tabIndex="0"
    >
      <div
        className={"popup-content"}
        onClick={(e) => {
          // console.log("ModalContent onclick");
          e.stopPropagation();
        }}
      >
        {state?.link ? (
          // <iframe src={"http://docs.google.com/viewer?url=" + state?.link + "&embedded=true"} width="100%" height="100%" style="width: 100%; height: 100%;" frameborder="0" scrolling="no"></iframe>

          <iframe
            style={{ height: "100%", width: "100%" }}
            width="100%"
            height="100%"
            src={state?.link}
          ></iframe>
        ) : null}
        {state?.modalChildren ? state?.modalChildren : null}
        <div className="popup-header">
          <button
            className="popup-close"
            onClick={(e) => {
              dispatch({
                type: "set_showModal",
                payload: { showModal: false },
              });
              document.body.style.overflow = "initial";

              e.stopPropagation();
            }}
          >
            {t("cerrar")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalContainer;
