import fetchApi from "ab-fetch-api";
import { PRODUCT_INFO } from "ab-api-fragments";

export async function getProduct(slug) {
  const data = await fetchApi(
    `
    query getProduct($slug: ID!) {
      product(id: $slug, idType: SLUG) {

        ${PRODUCT_INFO}
      }
    }    
  `,
    {
      variables: {
        slug,
      },
      errorPolicy: "ignore",
    }
  );
  return data;
}
