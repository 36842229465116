import "./ab-quantity-picker.scss";

const QuantityPicker = ({
  quantity = 0,
  setQuantity = () => {},
  disabled,
  allowZero,
  suffix = "UND.",
  noSuffix,
  i18n,
  ...rest
}) => {
  const _suffix = i18n.language == "en" ? "UN." : suffix;
  return (
    <div className="ab-quantity-picker">
      <button
        type="button"
        disabled={disabled}
        onClick={() => {
          if (quantity > 1 || allowZero) {
            setQuantity(quantity - 1);
          } else {
            setQuantity(1);
          }
        }}
      >
        -
      </button>
      <span>{`${quantity} ${noSuffix ? "" : _suffix}`}</span>
      <input value={quantity} {...rest} type="hidden" />
      <button
        type="button"
        disabled={disabled}
        onClick={() => {
          setQuantity(quantity + 1);
        }}
      >
        +
      </button>
    </div>
  );
};

export default QuantityPicker;
