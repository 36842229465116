import { updateCart } from "ab-functions";
import { addToCart, removeItemsFromCart } from "ab-api-mutations";

import { defaultState } from "./provider";

export const encodeToken = (token) => {
  if (!token) {
    return null;
  }

  return btoa(token);
};

export default function reducer(state, action) {
  // console.log("CartReducer", state, action);

  switch (action.type) {
    case "reset_cart":
      // localStorage.removeItem(process.env.CART_SESSION_NAME);
      return {
        ...defaultState,
      };
    case "set_cart":
    case "adding_item_to_cart":
      return {
        ...state,
        ...action.payload,
      };
    case "remove_coupon":
    case "apply_coupon":
    case "add_item_to_cart":
      let newCart;

      // if (state?.products?.length) {
      //   newCart = updateCart(state, action.payload.product, action.payload.qty);
      // } else {
      //   newCart = addFirstProduct(
      //     action.payload.product,
      //     action.payload.product.price,
      //     action.payload.qty
      //   );
      // }
      // localStorage.setItem(
      //   process.env.CART_SESSION_NAME,
      //   JSON.stringify(action.payload)
      // );
      return {
        ...state,
        ...action.payload,
      };
    case "error_coupon":
      return {
        ...state,
        ...action.payload,
      };
    case "change_itm_qty":
      removeItemsFromCart(action.payload.itn.node.key).then(() => {
        addToCart(action.payload.itm.node, -1).then((res) => {
          //  console.log("addToCart - Response", res);
        });
      });

      let updatedCart;
      if (action.payload.qty == 0) {
        let newProducts = state.products.filter(
          (itm) => itm.databaseId !== action.payload.itm.databaseId
        );
        let count = 0;
        newProducts.map((itm) => (count += itm.qty));
        return {
          ...state,
          products: [...newProducts],
          totalProductsCount: count,
        };
      } else {
        updatedCart = updateCart(
          state,
          action.payload.itm,
          true,
          action.payload.qty
        );
        return {
          ...state,
          ...updatedCart,
        };
      }

    default:
      console.log("CartReducer - error - action unknown", action);
  }
}
