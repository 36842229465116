import React, { useState, useEffect } from "react";

export const AppContext = React.createContext([
  {}, // Cart
  () => {}, // Cart add function
]);

export const AppProvider = (props) => {
  const [cart, setCart] = useState(null);

  // useEffect(() => {
  //   if (process.browser) {
  //     let cartData = localStorage.getItem(process.env.CART_SESSION_NAME);
  //     cartData = cartData !== null ? JSON.parse(cartData) : "";
  //     // console.log("CartContext", cartData);
  //     setCart(cartData);
  //   }
  // }, []);

  return (
    <AppContext.Provider value={[cart, setCart]}>
      {props.children}
    </AppContext.Provider>
  );
};
