import React, { useReducer, useEffect } from "react";

import { getCart } from "ab-api-mutations";

const defaultState = {
  loaded: false,
  addingItem: false,
};

import reducer from "./reducer";

const CartStateContext = React.createContext();
const CartDispatchContext = React.createContext();

const CartProvider = ({ children }) => {
  // const [state, dispatch] = useImmer({ ...defaultState });
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
  });

  useEffect(async () => {
    let token = localStorage.getItem(process.env.AUTH_TOKEN_NAME);
    let cartResponse = await getCart(btoa(token));
    // console.log("refreshCart", cartResponse);
    dispatch({
      type: "add_item_to_cart",
      payload: { ...cartResponse, addingItem: false, loaded: true },
    });
  }, []);

  // useEffect(() => {
  //   if (state.products) {
  //     localStorage.setItem(
  //       process.env.CART_SESSION_NAME,
  //       JSON.stringify(state)
  //     );
  //   }
  // }, [state]);

  // console.log("CartProvider - state - ", state);

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
};

export { defaultState, CartProvider, CartStateContext, CartDispatchContext };
