import { useContext } from "react";

import { ModalStateContext, ModalDispatchContext } from "./provider";

const useModalStateContext = () => {
  const state = useContext(ModalStateContext);

  if (state === undefined) {
    throw new Error("State must be defined");
  }

  return state;
};

const useModalDispatchContext = () => {
  const dispatch = useContext(ModalDispatchContext);
  const state = useContext(ModalStateContext);

  const setShowModal = async (showModal, link) => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }

    dispatch({
      type: "set_showModal",
      payload: { showModal: showModal, link: link },
    });
  };

  // console.log("ModalsProvider");

  return {
    ...state,
    setShowModal,
  };
};

const useModalContext = () => {
  return [useModalStateContext(), useModalDispatchContext()];
};

export { useModalContext };
