import fetchApi from "ab-fetch-api";
import { PRODUCT_INFO } from "ab-api-fragments";

export async function getProductsByCategory(where__categoryId) {
  const data = await fetchApi(
    `
  query products(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where__categoryId: Int 
  ) {
    products(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        categoryId: $where__categoryId
      }
    ) {
      nodes {
        ${PRODUCT_INFO}
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`,
    {
      variables: {
        where__categoryId,
      },
    }
  );
  return data;
}
