import fetchApi from "ab-fetch-api";

export default async function loginUser(email, password) {
  const data = await fetchApi(
    `
      mutation LoginUser($email: String!, $password: String!) {
        login(input: {username: $email, password: $password}) {
          authToken
          refreshToken
          sessionToken
          customer {
            id
            role
            sessionToken
          }
        }
      }`,
    {
      variables: { email, password },
    }
  );
  if (data.status === 500) {
    return data;
  } else {
    return data?.login;
  }
}
