import { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/de";
import weekday from "dayjs/plugin/weekday";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(weekday);
dayjs.extend(isSameOrAfter);

import Text from "ab-text";

import "./ab-calendar.scss";

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb && cb();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Calendar = ({ value, onChange, openDown, minimumDate, i18n }) => {
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setIsVisible(false);
  });

  dayjs.locale('es')
  const today = minimumDate
    ? dayjs()
      .date(dayjs(minimumDate).date() + 1)
      .month(dayjs(minimumDate).month())
    : dayjs().date(dayjs(minimumDate).date() + 1);

  let daysToShow = new Array(dayjs(value)?.daysInMonth())?.fill("");

  let daysBefore = new Array(
    dayjs(value)
      ?.startOf("month")
      .weekday()
  )?.fill("");

  const dayOfWeek = (number) => {
    if (i18n.language == "en") {
      const daysEN = ["M", "T", "W", "R", "F", "S", "U"];
      return daysEN[number];
    }
    if (i18n.language == "de") {
      const daysDE = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
      return daysDE[number];
    }
    const daysES = ["L", "M", "X", "J", "V", "S", "D"];
    return daysES[number];
  };

  return (
    <div className="ab-calendar">
      {isVisible ? (
        <div
          className={`calendar ${openDown ? "dropdown" : ""}`}
          ref={wrapperRef}
        >
          <div className="title">
            <Text element="span" className="title">
              {dayjs(value)
                .locale(i18n.language)
                .format("MMMM, YYYY")}
            </Text>
            <button
              onClick={() => {
                if (dayjs().month() != dayjs(value).month()) {
                  if (
                    dayjs().month() ==
                    dayjs(value)
                      .subtract(1, "month")
                      ?.month()
                  ) {
                    onChange(minimumDate ? dayjs(minimumDate) : dayjs());
                  } else {
                    onChange(
                      dayjs(value)
                        .subtract(1, "month")
                        .startOf("month")
                    );
                  }
                }
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6875 2.97994C10.865 3.15745 10.8812 3.43523 10.7359 3.63097L10.6875 3.68705L6.37465 8.00016L10.6875 12.3133C10.865 12.4908 10.8812 12.7686 10.7359 12.9643L10.6875 13.0204C10.51 13.1979 10.2323 13.214 10.0365 13.0688L9.98043 13.0204L5.31377 8.35372C5.13625 8.17621 5.12012 7.89843 5.26535 7.70269L5.31377 7.64661L9.98043 2.97994C10.1757 2.78468 10.4923 2.78468 10.6875 2.97994Z"
                  fill="#372F2F"
                />
              </svg>
            </button>

            <button
              onClick={() => {
                onChange(
                  dayjs(value)
                    .add(1, "month")
                    .startOf("month")
                );
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.31246 13.0201C5.13495 12.8425 5.11881 12.5648 5.26405 12.369L5.31246 12.313L9.62535 7.99984L5.31246 3.68672C5.13495 3.50921 5.11881 3.23144 5.26405 3.0357L5.31246 2.97962C5.48997 2.80211 5.76775 2.78597 5.96349 2.9312L6.01957 2.97962L10.6862 7.64628C10.8637 7.82379 10.8799 8.10157 10.7346 8.29731L10.6862 8.35339L6.01957 13.0201C5.82431 13.2153 5.50772 13.2153 5.31246 13.0201Z"
                  fill="#372F2F"
                />
              </svg>
            </button>
          </div>
          <div className="days-of-week">
            <span className="title">{dayOfWeek(0)}</span>
            <span className="title">{dayOfWeek(1)}</span>
            <span className="title">{dayOfWeek(2)}</span>
            <span className="title">{dayOfWeek(3)}</span>
            <span className="title">{dayOfWeek(4)}</span>
            <span className="title">{dayOfWeek(5)}</span>
            <span className="title">{dayOfWeek(6)}</span>
          </div>
          <div className="days">
            {daysBefore?.map((itm, index) => (
              <div key={index} />
            ))}
            {daysToShow?.map((itm, index) => {
              const tempDate = dayjs()
                .month(dayjs(value).month())
                .date(index + 1)
                .year(dayjs(value).year());
              return (
                <button
                  key={index}
                  className={
                    dayjs(value).date() == index + 1
                      ? "selected"
                      : tempDate.isBefore(today)
                        ? "disabled"
                        : undefined
                  }
                  onClick={() => {
                    if (tempDate.isSameOrAfter(today)) {
                      // console.log("Calendar - setDateValue", index, tempDate);
                      onChange(tempDate);
                      setIsVisible(false);
                    }
                  }}
                >
                  <span className="title">{index + 1}</span>
                </button>
              );
            })}
          </div>
        </div>
      ) : null}
      <span
        className={openDown ? (isVisible ? "hide" : "color") : ""}
        onClick={() => setIsVisible(!isVisible)}
      >
        {i18n?.language == "en"
          ? dayjs(value)
            .locale("en")
            .format("D MMMM")
          : i18n?.language == "de"
            ? dayjs(value)
              .locale("de")
              .format("D MMMM")
            : dayjs(value)
              .locale("es")
              .format("DD [de] MMMM")}
      </span>
    </div>
  );
};

export default Calendar;
