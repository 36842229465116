import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import { motion } from "framer-motion";

import "./text.scss";

const Text = ({
  element = "p",
  animated,
  className,
  children = "",
  ...rest
}) => {
  const myRef = useRef();
  const { inViewport, enterCount, leaveCount } = useInViewport(
    myRef,
    {},
    {
      onEnterViewport: () => {
        // console.log("entered viewport");
      },
    },
    { disconnectOnLeave: false }
  );

  const sentence = {
    hidden: {
      opacity: 1,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0,
        delayChildren: 0,
        duration: 0.8,
        staggerChildren: 0.3,
        type: "spring",
        ease: "easeInOut",
      },
    },
  };

  const letter = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      transition: {
        duration: 1.1,
        ease: "easeInOut",
      },
      opacity: 1,
      y: 0,
    },
  };

  if (element == "h2" && animated) {
    return (
      <div ref={myRef}>
        <motion.h2
          variants={sentence}
          animate={inViewport ? "visible" : "hidden"}
          className={className}
          {...rest}
        >
          {children?.split(" ").map((char, index) => {
            // console.log('char', char);
            if (char?.includes("<em>")) {
              return (
                <>
                  <motion.span
                    key={`${char + index}-${new Date().getTime()}`}
                    variants={letter}
                    dangerouslySetInnerHTML={{ __html: char }}
                  />
                  <span>&nbsp;</span>
                </>
              );
            }
            return (
              <motion.span
                key={`${char + index}-${new Date().getTime()}`}
                variants={letter}
              >
                {`${char}`}&nbsp;
              </motion.span>
            );
          })}
        </motion.h2>
      </div>
    );
  }

  return React.createElement(
    children?.includes("<ul>") && className !== "less" ? "div" : element || "p",
    {
      className:
        children?.includes("<ul>") && className !== "less"
          ? "inner-list"
          : className,
      ...rest,
      dangerouslySetInnerHTML: {
        __html: children,
      },
    }
  );
};

export default Text;
