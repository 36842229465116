import fetchApi from "ab-fetch-api";

export default async function emptyCart(token) {
  const data = await fetchApi(
    `
      mutation emptyCart {
        emptyCart(input: {clearPersistentCart: true}) {
          cart {
            isEmpty
          }
        }
      }`,
    {},
    token
  );

  if (data?.[0]) {
    return data;
  } else {
    return data?.cart?.isEmpty;
  }
}
