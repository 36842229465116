import Link from "next/link";
import { useTranslation } from "next-i18next";
import { getTranslation } from "./methods/getTranslation";

const SubMenuBodega = ({ setOpen, menu, t, i18n }) => {
  return (
    <div className={`submenu-inner hotel`}>
      <span>{t("tienda-vinos-exclusivos")}</span>

      <Link prefetch={false} href={t("/tienda")}>
        <a onClick={() => setOpen(false)}>{t("ver-todos")}</a>
      </Link>

      <span>{t("hilo-conductor")}</span>
      {menu?.bodega.vinos?.map((itm) => (
        <Link
          prefetch={false}
          href={getTranslation(itm, "uri", {
            code: i18n.language?.toUpperCase(),
          })}
        >
          <a onClick={() => setOpen(false)}>
            {getTranslation(itm, "title", {
              code: i18n.language?.toUpperCase(),
            })}
          </a>
        </Link>
      ))}
      <span>{t("comprar-seleccion")}</span>
      {menu?.bodega.categorias?.map((itm) => (
        <Link
          prefetch={false}
          href={getTranslation(itm, "uri", {
            code: i18n.language?.toUpperCase(),
          })}
        >
          <a onClick={() => setOpen(false)}>
            {getTranslation(itm, "title", {
              code: i18n.language?.toUpperCase(),
            })}
          </a>
        </Link>
      ))}
    </div>
  );
};

export default SubMenuBodega;
