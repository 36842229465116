import fetchApi from "ab-fetch-api";

export default async function registerUser(
  email,
  password,
  firstName,
  lastName,
  locale = "es_ES"
) {
  const data = await fetchApi(
    `
    mutation RegisterUser($email: String, $password: String, $firstName: String, $lastName: String, $locale: String) {
      registerCustomer(input: {email: $email, password: $password, firstName: $firstName, lastName: $lastName, locale: $locale}) {
        authToken
        refreshToken
        customer {
          id
        }
      }
    }`,
    {
      variables: { email, password, firstName, lastName, locale },
    }
  );
  // console.log("registerUser", data);
  if (data.status === 500) {
    return data;
  } else {
    return data?.registerCustomer;
  }
}
