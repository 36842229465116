import React from "react";
import { useTranslation } from "next-i18next";

import SubMenuHotel from "./SubMenuHotel";
import SubMenuGastronomia from "./SubMenuGastronomia";
import SubMenuExperiencias from "./SubMenuExperiencias";
import SubMenuSpa from "./SubMenuSpa";
import SubMenuConocenos from "./SubMenuConocenos";
import SubMenuCorporate from "./SubMenuCorporate";
import SubMenuBodega from "./SubMenuBodega";

import "./submenu.scss";

const SubMenu = ({
  type,
  menu,
  activeSection,
  setActiveSection,
  activeSubSection,
  setActiveSubSection,
  isSticky,
  tourLink,
  tourText,
  t,
  i18n,
}) => {
  // console.log('SubMenu', type, menu)
  const getType = () => {
    switch (type) {
      case "gastronomia":
        return SubMenuGastronomia;
      case "hotel":
        return SubMenuHotel;
      case "experienciasUnicas":
        return SubMenuExperiencias;
      case "spa":
        return SubMenuSpa;
      case "sostenibilidad":
        return SubMenuConocenos;
      case "contacta":
        return SubMenuCorporate;
      case "bodega":
        return SubMenuBodega;
      default:
        return null;
    }
  };

  if (menu) {
    return React.createElement(getType(type), {
      activeSection,
      setActiveSection,
      activeSubSection,
      setActiveSubSection,
      menu,
      type,
      isSticky,
      tourLink,
      tourText,
      t,
      i18n,
    });
  }

  return null;
};

export default SubMenu;
