import fetchApi from "ab-fetch-api";

export async function getPost(slug, language) {
  // console.log("getOnePost", slug, language);
  const data = await fetchApi(
    `
      query getOnePost($slug: ID!) {
        post(id: $slug, idType: SLUG) {
          title
          language {
            name
            code
          }
          translations {
            title
            link
            language {
              code
            }
          }
          contenidoFlexible {
            fieldGroupName
            modulos {
              ... on Post_Contenidoflexible_Modulos_BlogPost {
                fieldGroupName
                content
                classname
              }
              ... on Post_Contenidoflexible_Modulos_Disfrute {
                fieldGroupName
                classname
              }
              ... on Post_Contenidoflexible_Modulos_CrossDisfruteDelWellness {
                fieldGroupName
                classname
              }
              ... on Post_Contenidoflexible_Modulos_CrossExperienciasUnicas {
                fieldGroupName
                classname
              }
              ... on Post_Contenidoflexible_Modulos_CrossRegaleUnaExperiencia {
                fieldGroupName
                classname
              }
              ... on Post_Contenidoflexible_Modulos_CrossGastronomiaDeAbadia {
                fieldGroupName
                classname
              }
              ... on Post_Contenidoflexible_Modulos_CrossUnHotelASuMedida {
                fieldGroupName
                classname
              }
              
            }
          }
        }
      }
      
      `,
    {
      variables: {
        slug,
      },
    }
  );
  return data;
}
