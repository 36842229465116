import { filter } from "lodash-es";

export default function getMenuTranslation(translations, locale) {
  const translation =
    filter(
      translations,
      (trans) => trans?.language?.code == locale?.toUpperCase()
    ) || [];

  // console.log('getMenuTranslation', translation);

  return translation[0];
}
