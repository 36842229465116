import Link from "next/link";

import { getTranslation } from "./methods/getTranslation";

const SubMenuHotelInner = ({ setOpen, menu, t, i18n }) => {
  // console.log("SubMenuHotelInner", menu);

  const tourLink =
    i18n.language == "en"
      ? menu?.menuSuperior?.tour?.linken ||
        "https://gemelosdigitales.3dvirtualtours.es/r/7efRb"
      : menu?.menuSuperior?.tour?.link;

  return (
    <div className={`submenu-inner hotel`}>
      {menu?.hotel.item.map((itm) => {
        // console.log("SubMenuHotelInner", itm);

        const isLeaders =
          itm?.externallink?.title == "Leaders Club" ||
          itm?.externallink?.title?.includes("Leader");
        const getLeaders = () => {
          if (isLeaders) {
            if (i18n.language == "en") {
              return itm?.externallink?.url?.replace("//es", "//www");
            } else if (i18n.language == "de") {
              return itm?.externallink?.url?.replace("//es", "//de");
            } else {
              return itm?.externallink?.url;
            }
          }
        };

        if (itm?.link?.title == "Tour Virtual") {
          return (
            <a href={tourLink} target={itm.externallink.target}>
              {menu?.menuSuperior?.tour[`texto${i18n.language}`] ||
                "Tour Virtual"}
            </a>
          );
        }

        if (isLeaders) {
          return (
            <div>
              <a href={getLeaders()} target={itm.externallink.target}>
                {i18n.language !== "es"
                  ? getTranslation(itm, "title", {
                      code: i18n.language?.toUpperCase(),
                    }) || itm.externallink.title
                  : itm.externallink.title}
              </a>
            </div>
          );
        }
        return (
          <div>
            {itm.externallink ? (
              <a href={itm.externallink.url} target={itm.externallink.target}>
                {i18n.language !== "es"
                  ? getTranslation(itm, "title", {
                      code: i18n.language?.toUpperCase(),
                    }) || itm.externallink.title
                  : itm.externallink.title}
              </a>
            ) : (
              <Link
                prefetch={false}
                href={getTranslation(itm, "uri", {
                  code: i18n.language?.toUpperCase(),
                })}
              >
                <a onClick={() => setOpen(false)}>
                  {getTranslation(itm, "title", {
                    code: i18n.language?.toUpperCase(),
                  })}
                </a>
              </Link>
            )}
            {itm.description ? <p>{itm.descripcion}</p> : null}
          </div>
        );
      })}
    </div>
  );
};

export default SubMenuHotelInner;
