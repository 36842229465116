import NextLink from "next/link";

import "./ab-link.scss";

const Link = ({
  text,
  title,
  url,
  href,
  target,
  className,
  onClick,
  prefetch,
  callback,
  ...rest
}) => {
  const renderInner = () => {
    return (
      <a
        href={href || url}
        target={target}
        className={`ab-link ${className || ""}`}
        onClick={callback ? () => callback() : () => {}}
        {...rest}
      >
        {text || title}
      </a>
    );
  };
  if (onClick) {
    return (
      <a onClick={onClick} className={`ab-link ${className || ""}`} {...rest}>
        {text || title}
      </a>
    );
  }

  if (href?.includes("http")) {
    return renderInner();
  }

  if (href) {
    return (
      <NextLink href={href} prefetch={prefetch}>
        {renderInner()}
      </NextLink>
    );
  }

  return renderInner();
};

export default Link;
