const parseWCPriceToFloat = (price) => {
  return parseFloat(
    parseFloat(
      price
        ?.toString()
        ?.replace(".", "")
        ?.replace(",", ".")
    )?.toFixed(2) || 0
  );
};

export default parseWCPriceToFloat;
