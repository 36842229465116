import fetchApi from "ab-fetch-api";
import { CART_INFO } from "ab-api-fragments";

export default async function removeCoupon(code) {
  const data = await fetchApi(
    `
      mutation removeCoupon($code: [String]) {
        removeCoupons(input: {codes: $code}) {
${CART_INFO}
        }
      }
    `,
    {
      variables: { code },
    }
  );

  if (data?.[0]) {
    return data;
  } else {
    return data;
  }
}
