import { PRODUCT_INFO } from "./product-info";

export const ORDER_INFO = `
billing {
  firstName
  lastName
  address1
  city
  phone
  postcode
  state
  email
  company
}
shipping {
  firstName
  lastName
  address1
  city
  postcode
  state
  phone
}
status
discountTax(format: FORMATTED)
discountTotal(format: FORMATTED)
total(format: FORMATTED)
totalTax(format: FORMATTED)
subtotal(format: FORMATTED)
shippingTax(format: FORMATTED)
shippingTotal(format: FORMATTED)
paymentMethodTitle
orderNumber
orderKey
couponLines {
  edges {
    node {
      code
      discount
      discountTax
    }
  }
}
customerNote
date
metaData(keysIn: [
  "particular_o_empresa", 
  "billing_razon_social", 
  "nif", 
  "products_cart",
  "shipping_phone"
]) {
  key
  value
}
customer {
  email
}

lineItems {
  nodes {
    databaseId
    product {
      ${PRODUCT_INFO}
    }
    productId
    quantity
    subtotal
    subtotalTax
    total
    totalTax
    metaData {
      key
      value
    }
  }
}

`;
