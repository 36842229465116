import fetchApi from "ab-fetch-api";
import { PRODUCT_INFO } from "ab-api-fragments";

export async function getCategory(slug) {
  const data = await fetchApi(
    `
    query getCategory($slug: ID!) {
      productCategory(id: $slug, idType: SLUG) {
        name
        description
        slug
        products {
          nodes {
            ${PRODUCT_INFO}
          }
        }
      }
    }
    
        
  `,
    {
      variables: {
        slug,
      },
    }
  );
  return data;
}
